import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { brandingSelector } from 'src/selectors/selectors';

function HelmetConfig() {
  const branding = useSelector(brandingSelector);
  const domainAlias = useSelector(state => state?.config?.domain?.domainAlias);

  const baseImagesUrl = process.env.REACT_APP_BRAND_LOGO_BASE_URL;
  const PUBLIC_URL = process.env.PUBLIC_URL;
  const defaultLogo = PUBLIC_URL + '/favicon.ico';
  const imgUrl = branding?.brandFavIconFilePath
    ? `${baseImagesUrl}${branding?.brandFavIconFilePath}`
    : defaultLogo;

  const isCustomDomain = useMemo(
    () => window.location.href.indexOf('tracknow.io') === -1,
    [window.location.href]
  );
  return (
    <Helmet>
      <title>{branding?.seoTitle || domainAlias}</title>
      {!!branding?.seoDescription && (
        <meta name="description" content={branding?.seoDescription} />
      )}
      <link rel="shortcut icon" href={imgUrl} />
      <link rel="icon" type="image/png" href={imgUrl} sizes="64x64" />
      <link
        rel="mask-icon"
        type="image/png"
        href={imgUrl}
        color="#000000"
        sizes="64x64"
      />

      {isCustomDomain ? (
        !branding?.seoIndex && <meta name="robots" content="noindex" />
      ) : (
        <meta name="robots" content="noindex" />
      )}
    </Helmet>
  );
}

export default HelmetConfig;
